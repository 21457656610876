<template>
  <div class="flights-results-cont">
    <overlay v-if="$store.state.flightsTimeOut && flightsStore" @newSearch="newSearch" />
    <div class="background d-none d-md-block">
      <search :flightLoading="searchBtnLoad" @addFlights="getFlights" />
    </div>
    <v-snackbar v-model="snackbar" top :timeout="5000" :color="color">
      <v-row>
        {{ text }}
        <v-spacer></v-spacer>
        <v-btn icon @click="snackbar = false"
          ><v-icon color="white">mdi-close</v-icon></v-btn
        >
      </v-row>
    </v-snackbar>
    <v-container fluid class="px-5 mt-10">
      <v-expansion-panels hover class="d-block d-md-none">
        <v-expansion-panel>
          <v-expansion-panel-header expand-icon="mdi-magnify"
            >Search Flights</v-expansion-panel-header
          >
          <v-expansion-panel-content color="#fff">
            <search :flightLoading="searchBtnLoad" @addFlights="getFlights" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row
        justify="center"
        align="center"
        style="height: 60vh"
        v-if="!flightsStore && loaded"
      >
        <div>
          <v-img max-width="200" class="blink-2" src="../static/ossc_logo-02.png"></v-img>
        </div>
      </v-row>
      <v-row v-if="flightsStore && !loaded">
        <v-col cols="12" class="d-block d-md-none mt-2">
          <v-expansion-panels hover>
            <v-expansion-panel v-if="flightsStore.length > 1">
              <v-expansion-panel-header expand-icon="mdi-filter-menu"
                >Filter Results</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <filters
                  :alternateFlightsExist="alternateFlightsExist"
                  v-if="showMatrix"
                  @changed="filter"
                  :mailonlyFaresExist="mailonlyFaresExist"
                />
                <p v-else class="text-center">Preparing Filters ...</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col
          cols="3"
          xl="2"
          class="d-none filter-stick d-md-block"
          v-if="flightsStore.length > 1"
        >
          <v-card class="pa-1" width="100%">
            <filters
              v-if="showMatrix"
              @changed="filter"
              :alternateFlightsExist="alternateFlightsExist"
              :mailonlyFaresExist="mailonlyFaresExist"
            />
            <p v-else class="text-center">Preparing Filters ...</p>
          </v-card>
        </v-col>
        <v-col
          v-if="flights.length == 0 && !loaded"
          cols="12"
          :md="flightsStore.length === 1 ? 12 : 9"
        >
          <p class="body-1 font-weight-bold mx-auto primary--text text-center">
            Sorry, No flights found .
            <span v-if="alternateDatesFound"
              >Please wait we are preparing alternate date flights for you ...</span
            >
          </p>
        </v-col>
        <v-col
          cols="12"
          :md="flightsStore.length === 1 ? 12 : 9"
          :xl="flightsStore.length === 1 ? 12 : 10"
          v-if="flights.length > 0"
        >
          <div v-if="$store.state.flightType === 'one'" class="mb-5">
            <div class="d-flex">
              <div class="blueDark--text text-h5 font-weight-bold">
                {{ $store.state.airports.departureAirports[0].Name }}
              </div>
              <v-icon x-large color="blueDark" class="mx-5">mdi-ray-start-arrow</v-icon>
              <div class="blueDark--text text-h5 font-weight-bold">
                {{ $store.state.airports.arrivalAirports[0].Name }}
              </div>
            </div>
          </div>
          <div v-if="$store.state.flightType === 'round'" class="mb-5">
            <div class="d-flex">
              <div class="blueDark--text text-h5 font-weight-bold">
                {{ $store.state.airports.departureAirports[0].Name }}
              </div>
              <v-icon x-large color="blueDark" class="mx-5">mdi-swap-horizontal</v-icon>
              <div class="blueDark--text text-h5 font-weight-bold">
                {{ $store.state.airports.arrivalAirports[0].Name }}
              </div>
            </div>
          </div>
          <div v-if="$store.state.flightType === 'multi'" class="mb-5">
            <div class="d-flex">
              <div class="blueDark--text text-h5 font-weight-bold">
                {{ $store.state.airports.departureAirports[0].Name }}
              </div>
              <v-icon x-large color="blueDark" class="mx-5 pb-2"
                >mdi-ray-start-arrow</v-icon
              >
              <div class="blueDark--text text-h5 font-weight-bold">
                {{ $store.state.airports.arrivalAirports[0].Name }}
              </div>
              <v-icon x-large color="blueDark" class="mx-5 pb-2"
                >mdi-ray-start-arrow</v-icon
              >
              <div class="blueDark--text text-h5 font-weight-bold">
                {{ $store.state.airports.arrivalAirports[1].Name }}
              </div>
              <v-icon
                v-if="$store.state.airports.arrivalAirports[2]"
                x-large
                color="blueDark"
                class="mx-5 pb-2"
                >mdi-ray-start-arrow</v-icon
              >
              <div
                class="blueDark--text text-h5 font-weight-bold"
                v-if="$store.state.airports.arrivalAirports[2]"
              >
                {{ $store.state.airports.arrivalAirports[2].Name }}
              </div>
            </div>
          </div>
          <matrix
            :alternateFlightsExist="alternateFlightsExist"
            @choose="filter"
            :netMatrix="netMatrix"
            :alterMatrix="alterMatrix"
            v-if="flightsStore.length > 1 && showMatrix"
          />
          <div style="width: 100%; text-align: center">
            <v-carousel
              height="100"
              :show-arrows="false"
              v-if="alternateLoad && alternateFlights.length === 0"
              continuous
              cycle
              interval="1000"
              hide-delimiters
            >
              <v-carousel-item
                v-for="(item, i) in $store.state.flightResults.FilterMatrix"
                :key="i"
              >
                <v-img class="mx-auto" max-width="100" :src="item.AirlineLogo"></v-img>
              </v-carousel-item>
            </v-carousel>
          </div>
          <!-- <v-alert
                  color="warning"
                  dark
                  icon="mdi-alert"
                  border="left"
                  dismissible
                  >
                    COVID-19 alert: Travel requirements are changing rapidly. Including need for pre-travel COVID-19 testing and quarantine on arrival.
                  </v-alert> -->
          <v-alert
            color="success"
            dark
            icon="mdi-alert"
            border="left"
            dismissible
            v-if="$store.state.flightResults.nonDirectFlightNote"
          >
            {{ $store.state.flightResults.nonDirectFlightNote }}
          </v-alert>
          <v-divider
            v-if="!alternateLoad && alternateFlights.length > 0"
            class="my-5"
          ></v-divider>
          <flightCard :flights="flights" />
        </v-col>
      </v-row>
      <p
        v-if="!flightsStore && !loaded"
        class="body-1 font-weight-bold primary--text text-center mt-5"
      >
        Sorry, No flights match your search.
        <span v-if="alternateDatesFound"
          >Please wait we are preparing alternate date flights for you ...</span
        >
      </p>
    </v-container>
  </div>
</template>

<script>
// import search from '../components/flightSearch.vue'
import search from '../components/searchScreen.vue'
import flightCard from '../components/flightCard.vue'
import filters from '../components/filters.vue'
import matrix from '../components/matrix.vue'
import overlay from '../components/overlay.vue'
import { searchFlights, headersNoAuth, headers } from '../links'

export default {
  title: '-Flight results',
  components: {
    search,
    flightCard,
    filters,
    matrix,
    overlay
  },
  data () {
    return {
      model: 0,
      flights: [],
      snackbar: false,
      color: '',
      text: '',
      alternateLoad: true,
      alternateFlights: [],
      netMatrix: [],
      alterMatrix: [],
      showMatrix: false,
      loaded: true,
      alternateDatesFound: true,
      searchBtnLoad: false,
      alternateFlightsExist: false,
      mailonlyFaresExist: false
    }
  },
  computed: {
    flightsStore () {
      return this.$store.state.flightResults.flights
    },
    min () {
      return this.$store.state.flightResults.minPrice
    },
    max () {
      return this.$store.state.flightResults.maxPrice
    },
    requestHeaders () {
      if (this.$cookies.isKey('userToken')) { return headers(this.$cookies.get('userToken')) } else return headersNoAuth()
    }
  },
  // watch: {
  //   flightsStore (newVal) {
  //     if (newVal) {
  //       console.log(newVal.length, newVal.filter((flight) => !flight.withAlternateDate))
  //       this.flights = newVal.filter((flight) => !flight.withAlternateDate)
  //     }
  //   }
  // },
  methods: {
    airLabel (name, code, price) {
      const label = name + code + price
      return label
    },
    filter (flights) {
      if (flights.length > 0) {
        this.flights = flights
      }
    },
    newSearch (data) {
      this.loaded = true
      this.getFlights(data)
    },
    getFlights (data) {
      this.showMatrix = false
      this.searchBtnLoad = true
      this.$store.dispatch('removeFlightResults')
      this.loaded = true
      this.alternateDatesFound = true
      document.body.style.height = '100%'
      // this.$http.get(searchFlights(data), { headers: this.requestHeaders }).then(response => response.json()).then(response => {
      this.$http
        .post(
          searchFlights(data),
          {
            date: this.$store.state.flightDates.departureDates[0],
            visitor: this.$store.state.guestData
          },
          { headers: this.requestHeaders }
        )
        .then((response) => response.json())
        .then((response) => {
          this.$store.dispatch('setFlightsTimeOut', false)
          this.$store.dispatch(
            'setFlightsSearchTimeoutFunction',
            setTimeout(() => {
              this.$store.dispatch('setFlightsTimeOut', true)
            }, 20 * 60 * 1000)
          )
          this.$store.dispatch('setFlightResults', response.data)
          if (response.data.flights.length > 0) this.loaded = false
          localStorage.setItem('alternateSearchTerm', data)
        })
        .then(
          () => {
            if (this.flightsStore) {
              const newArr = []
              if (this.$store.state.flightResults.flights.length > 0) {
                this.$store.state.flightResults.flights.forEach((flight) => {
                  // this.alternateFlightsExist = true
                  // if (flight.alternate === undefined && flight.netFlight === undefined) {
                  newArr.push(flight)
                  if (flight.withAlternateDate) {
                    this.alternateFlightsExist = true
                  }
                })
              }
              this.flights = newArr
              this.getAdditionalFlights()
            } else {
              this.searchBtnLoad = false
              this.alternateDatesFound = false
              this.alterMatrix = []
            }
          },
          () => {
            this.snackbar = true
            this.color = 'error'
            this.text = 'Could not find flights!'
            this.loaded = false
            this.searchBtnLoad = false
            this.alternateDatesFound = false
          }
        )
    },
    getAdditionalFlights () {
      this.alternateLoad = true
      const results = this.$store.state.flightResults
      const sessionId = this.$store.state.flightResults.search_id
      this.$http
        .get(
          searchFlights(
            localStorage.getItem('alternateSearchTerm') + `&search_id=${sessionId}`
          ),
          { headers: headersNoAuth() }
        )
        .then(
          (response) => {
            this.searchBtnLoad = false
            this.alternateDatesFound = false
            this.alterMatrix = []
            if (response.body.status === true) {
              if (this.flights.length > 0) {
                this.alternateLoad = false
                const alternateFlights = response.body.data.flights.reduce(
                  (acc, current) => {
                    const x = acc.find((item) => {
                      return (
                        item.pricingInfo.TotalFare === current.pricingInfo.TotalFare &&
                        item.flightSegments[0].DepartureDate ===
                          current.flightSegments[0].DepartureDate &&
                        item.flightSegments[0].MarketingAirlineName ===
                          current.flightSegments[0].MarketingAirlineName
                      )
                    })
                    if (!x) {
                      return acc.concat([current])
                    } else {
                      return acc
                    }
                  },
                  []
                )
                const cheapestFlight = this.flights.sort((a, b) => {
                  let x
                  let y
                  if (a.pricingInfo.newFare > 0) x = a.pricingInfo.newFare
                  else x = a.pricingInfo.TotalFare
                  if (b.pricingInfo.newFare > 0) y = b.pricingInfo.newFare
                  else y = b.pricingInfo.TotalFare
                  return x - y
                })[0]
                const cheapestAltFlight = alternateFlights.sort((a, b) => {
                  let x
                  let y
                  if (a.pricingInfo.newFare > 0) x = a.pricingInfo.newFare
                  else x = a.pricingInfo.TotalFare
                  if (b.pricingInfo.newFare > 0) y = b.pricingInfo.newFare
                  else y = b.pricingInfo.TotalFare
                  return x - y
                })[0]
                if (
                  cheapestAltFlight.pricingInfo.TotalFare <
                  cheapestFlight.pricingInfo.TotalFare
                ) {
                  this.alterMatrix = response.body.data.FilterMatrix
                  if (response.body.data.minPrice < results.minPrice) {
                    this.$store.dispatch(
                      'setFlightsMinPrice',
                      response.body.data.minPrice
                    )
                  }
                  if (response.body.data.maxPrice > results.maxPrice) {
                    this.$store.dispatch(
                      'setFlightsMaxPrice',
                      response.body.data.maxPrice
                    )
                  }
                  if (response.body.data.minStopsDuration < results.minStopsDuration) {
                    this.$store.dispatch(
                      'setFlightsMinStopsDuration',
                      response.body.data.minStopsDuration
                    )
                  }
                  if (response.body.data.maxStopsDuration > results.maxStopsDuration) {
                    this.$store.dispatch(
                      'setFlightsMaxStopsDuration',
                      response.body.data.maxStopsDuration
                    )
                  }
                  if (response.body.data.airlines.length > 0) { this.$store.dispatch('setAltAirlines', response.body.data.airlines) }
                  alternateFlights.forEach((item, i) => {
                    this.flights.push(item)
                  })
                  this.alternateFlightsExist = true
                } else {
                  this.alternateLoad = false
                }
              } else {
                this.$store.dispatch('setFlightResults', response.body.data)
                this.flights = response.body.data.flights.reduce((acc, current) => {
                  const x = acc.find((item) => {
                    return (
                      item.pricingInfo.TotalFare === current.pricingInfo.TotalFare &&
                      item.flightSegments[0].DepartureDate ===
                        current.flightSegments[0].DepartureDate &&
                      item.flightSegments[0].MarketingAirlineName ===
                        current.flightSegments[0].MarketingAirlineName
                    )
                  })
                  if (!x) {
                    return acc.concat([current])
                  } else {
                    return acc
                  }
                }, [])
                this.$store.dispatch('setFlightsMinPrice', response.body.data.minPrice)
                this.$store.dispatch('setFlightsMaxPrice', response.body.data.maxPrice)
                this.$store.dispatch(
                  'setFlightsMinStopsDuration',
                  response.body.data.minStopsDuration
                )
                this.$store.dispatch(
                  'setFlightsMaxStopsDuration',
                  response.body.data.maxStopsDuration
                )
                this.$store.dispatch('setAltAirlines', response.body.data.airlines)
                this.alternateLoad = false
              }
            } else {
              this.alternateLoad = false
            }
            this.flights = this.flights.reduce((acc, current) => {
              const x = acc.find((item) => {
                if (item.mailOnlyFare) this.mailonlyFaresExist = true
                return (
                  item.mailOnlyFare &&
                  current.mailOnlyFare &&
                  item.pricingInfo.TotalFare === current.pricingInfo.TotalFare
                )
              })
              if (!x) {
                return acc.concat([current])
              } else {
                return acc
              }
            }, [])
            this.flights.sort((a, b) => {
              let x
              let y
              if (a.pricingInfo.newFare > 0) x = a.pricingInfo.newFare
              else x = a.pricingInfo.TotalFare
              if (b.pricingInfo.newFare > 0) y = b.pricingInfo.newFare
              else y = b.pricingInfo.TotalFare
              return x - y
            })
            this.$store.dispatch('updateFlights', this.flights)
            this.showMatrix = true
            this.loaded = false
          },
          () => {
            this.alternateLoad = false
            this.searchBtnLoad = false
            this.loaded = false
          }
        )
    }
  },
  created () {
    window.scrollTo(0, 0)
    let data
    if (this.$route.params.term) data = this.$route.params.term
    else data = localStorage.getItem('alternateSearchTerm')
    this.getFlights(data)
  },
  beforeCreate () {
    if (!this.$route.params.term && !localStorage.getItem('alternateSearchTerm')) { this.$router.push({ name: 'flights' }) }
  }
}
</script>

<style>
.background {
  height: auto;
  min-height: calc(40vh);
  background: linear-gradient(#325aaf8c, #325aaf8c), url("../static/bg.png");
  background-color: #333;
  background-size: 100% 100%;
  background-position: center center;
}
.background .container {
  padding-top: 25px;
}
/* .flights-results-cont {
      background-color: rgb(246, 246, 246);
    } */
.blink-2 {
  -webkit-animation: blink-2 1.5s infinite both;
  animation: blink-2 1.5s infinite both;
}
@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
</style>
